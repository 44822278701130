<template>
  <div id="dashboard_pagecontainer_master" class="canna_personalInfo_pageZone">

    <div style="position: relative;display: flex;flex-direction: column;height: 100%; overflow: hidden;">

      <!-- Zona de trabajo -->
      <div class="ppi_pageZone" v-if="initCatData===true">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>

    </div>

    <!-- Loaders --> 
    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: 0px; z-index: 8000;position: fixed;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

    <q-inner-loading :showing="zloadingData" style="top: 0px; z-index: 8000;position: fixed;">
      <q-spinner-gears :size="(pageSize === 'sm' || pageSize === 'xs') ? '90px' : '120px' " color="cannaLoading" />
    </q-inner-loading>

  </div>
</template>

<script>
import { requestPack } from './../../../boot/axios' // APP code 

import logOut_Mixin from './../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../mixins/helperCatsMix.js'

import { sectionRutas } from './../../../mixins/questionario/personalinfo.js'

export default {
  name: 'vacantes_main_screen',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      aa_pagesRef: sectionRutas,
      aa_headerZones: ['a','b','c','d'],
      debugEls: false,
      // --------
      initCatData: false,
      // --------
      zinitScreen: false,
      zloadingData: false,
      loading_Process:false
    }
  },
  computed: {
    testProfile(){
      return this.getProfileData
    },
  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- Vacantes MainScreen -- test')
    },
    showLoadr ( typeUse, showVal ) {
      if (typeUse === 1 ) {
        this.loading_Process =  (showVal === true )  ? true : false 
      } else if (typeUse === 2 ) {
        this.zloadingData =  (showVal === true )  ? true : false 
      }
      this.$forceUpdate()
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- Vacantes MainScreen -- beforeCreate')
    this.initCatData = false
  },
  created () {
    this.$cannaDebug('-- Vacantes MainScreen -- created')
    this.zloadingData = true
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- Vacantes MainScreen --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- Vacantes MainScreen -- mounted')
    if (this.initCatData===false){

      // if (this.$store.state.myapp.helperCatalog.length<=0){ 
      //   await this.helper_getAllCatalogs().then(respx =>{
      //     this.$cannaDebug('-- Vacantes MainScreen -- get All Catalogs -- finished ')
      //   })
      //   await this._waitRequestProc(1500)
      // }

      this.initCatData = true
    }
    this.zloadingData = false
  },
  beforeUpdate () {
    // this.$cannaDebug('-- Vacantes MainScreen -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- Vacantes MainScreen -- updated')
  },
  activated () {
    this.$cannaDebug('-- Vacantes MainScreen -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- Vacantes MainScreen -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- Vacantes MainScreen -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- Vacantes MainScreen -- destroyed')
  }
}
</script>
